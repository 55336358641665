* {
  box-sizing: border-box;
  font-family: 'Microsoft YaHei';
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: #666;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 4px;
  background-color: #666;
}

::-webkit-scrollbar-track-piece {
  width: 8px;
  height: 4px;
  background-color: #f0f0f0;
}

body {
  font-size: 14px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.auto {
  display: block;
  margin: 0 auto;
  width: 1500px;
}

.iconfont {
  font-family: iconfont;
}

@font-face {
  font-family: 'iconfont';  /* Project id 2682726 */
  src: url('//at.alicdn.com/t/font_2682726_rhvxrn9l22o.woff2?t=1627187667605') format('woff2'),
       url('//at.alicdn.com/t/font_2682726_rhvxrn9l22o.woff?t=1627187667605') format('woff'),
       url('//at.alicdn.com/t/font_2682726_rhvxrn9l22o.ttf?t=1627187667605') format('truetype');
}

.animate {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation: fadeInUp 1.2s;
  animation-fill-mode: forwards;
}
.fadeInUp1 {
  opacity: 0;
  animation: fadeInUp1 1.2s;
  animation-fill-mode: forwards;
}
.fadeInUp2 {
  opacity: 0;
  animation: fadeInUp2 1.2s;
  animation-fill-mode: forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 80%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInUp1 {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}


#newBridge {
  display: none!important;
}
#newBridge.partner-modal {
  display: block!important;
}
#smartInviteOuter {
  display: none!important;
}