.footer {
  width: 100%;
  height: 317px;
  font-size: 14px;
  line-height: 1.5;
  color: #dfdfdf;
  background: #0d0d0d;
  &-logo {
    margin-top: 48px;
    width: 220px;
    height: 75px;
  }
  &-container {
    margin: 0 auto;
    width: 1500px;
    height: 100%;
    padding: 42px 66px 36px;
    display: flex;
    // align-items: center;
  }
  &-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-right {
    width: 250px;
  }
  &-code {
    margin-top: 26px;
    width: 96px;
    height: 96px;
  }
  .line {
    margin: 0 114px 0 132px;
    width: 1px;
    height: 100%;
    background: #fff;
  }
  .t1 {
    margin-bottom: 20px;
  }
  .t2 {
    margin-bottom: 16px;
  }
  .num {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
  }
  &-bottom {
    height: 42px;
    background: #333;
    &-center {
      display: flex;
      margin: 0 auto;
      width: 1500px;
      height: 42px;
      align-items: center;
      justify-content: center;
    }
  }
}