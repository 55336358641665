.honor-page {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .pic-list {
    flex: 1;
    margin: 0 auto;
    width: 1700px;
    display: flex;
    justify-content: space-between;
    li {
      cursor: pointer;
      width: 408px;
      height: 278px;
      box-sizing: border-box;
      padding: 10px;
      border: 1px solid #e8e8e8;
      border-radius: 2px;
    }
    .pic {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}