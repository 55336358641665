.side-tab {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 9999;
  transform: translateY(-50%);
  .item {
    margin-bottom: 4px;
    width: 52px;
    height: 52px;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s linear;
    &:hover {
      background: #6d6d6d;
      .item-show {
        display: block;
      }
    }
    span {
      font-size: 24px;
      color: #333;
    }
    &-show {
      position: absolute;
      top: 0;
      right: 56px;
      width: 260px;
      height: 220px;
      background: #d9d9d9;
      display: none;
    }
    &-pshow {
      padding: 62px 0 0 22px;
      color: #000;
      .t1 {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: bold;
      }
      .t2 {
        margin-bottom: 24px;
        font-size: 12px;
      }
      .t3 {
        margin-bottom: 20px;
        font-size: 22px;
      }
      .t4 {
        font-size: 14px;
      }
    }
    &-cshow {
      padding: 22px 0 0 42px;
      .code {
        width: 172px;
        height: 172px;
      }
    }
    &-mshow {
      padding: 22px 22px 0 22px;
      .t1 {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: bold;
      }
      .t2 {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .row {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      input {
        padding: 0 6px;
        background: #cdcdcd;
        outline: none;
        border: none;
        height: 24px;
        border-radius: 4px;
        &::placeholder {
          color: rgba(0,0,0,.55);
        }
      }
      .input1 {
        width: 74px;
      }
      .input2 {
        width: 100px;
      }
      textarea {
        padding: 6px;
        width: 100%;
        background: #cdcdcd;
        outline: none;
        border: none;
        height: 64px;
        border-radius: 4px;
        &::placeholder {
          color: rgba(0,0,0,.55);
        }
      }
      .btn-wrap {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .btn {
        font-size: 12px;
        width: 74px;
        height: 18px;
        color: #fff;
        text-align: center;
        background: #313131;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}