.partner-page {
  .swiper-container {
    position: relative;
    height: 780px;
    .img-box {
      width: 100%;
      height: 100%;
      p {
        position: absolute;
        left: -360px;
        bottom: 54px;
        width: 360px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        color: #fff;
        font-weight: bold;
        font-size: 22px;
        text-shadow: 0 0 10px rgba(0, 0, 0, .5);
        background: rgba(173, 173, 173, .5);
        opacity: 0;
        transition: all .3s linear;
        &.active {
          left: 0;
          opacity: 1;
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      bottom: 60px;
      width: 100%;
      z-index: 999;
      .swiper-pagination-bullet {
        display: inline-block;
        margin-left: 30px;
        position: relative;
        width: 80px;
        height: 4px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
        cursor: pointer;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9999;
            content: "";
            display: block;
            width: 80px;
            height: 4px;
            border-radius: 2px;
            background: #d64648;
            animation: myAnimate 5s linear;
            // animation-iteration-count: infinite;
          }
        }
      }
    }
  }
  .slogan {
    position: absolute;
    right: 110px;
    bottom: 105px;
    z-index: 9;
    width: 928px;
    height: 492px;
    display: flex;
    &-left {
      padding: 30px 0 0 50px;
      width: 272px;
      height: 100%;
      background: rgba(125, 0, 0, .8);
    }
    &-right {
      flex: 1;
      padding: 72px 60px 0 56px;
      background: rgba(255, 255, 255, .9);
      .title {
        margin-bottom: 26px;
        width: 52px;
        height: 26px;
        line-height: 24px;
        text-align: center;
        color:#7ebc17;
        border: 1px solid #7ebc17;
        border-radius: 4px;
        font-size: 14px;
      }
      .txt {
        font-size: 14px;
        color: #000;
        line-height: 2.5;
      }
      .red {
        color: #c23e3f;
      }
      &-b {
        margin-top: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .code {
          width: 108px;
          height: 108px;
        }
      }
      .sub {
        margin-bottom: 10px;
        font-size: 20px;
        color: #323232;
      }
      .num {
        font-size: 32px;
        font-style: italic;
        color: #090707;
      }
    }
  }
  .home {
    width: 1500px;
    margin: 145px auto 160px;
    display: flex;
    align-items: center;
    &-center {
      flex: 1;
      margin: 0 60px;
      font-size: 18px;
      color: #373737;
      line-height: 1.5;
      img {
        display: block;
        margin: 30px auto 0;
      }
    }
  }
  .cmps {
    padding-bottom: 146px;
    width: 1500px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      position: relative;
      margin-bottom: 26px;
      width: 450px;
      height: 130px;
      background: #e2e2e2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background: #e2e2e2;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      &1 { background-image: url('../../assets/c-sx.jpg');}
      &2 { background-image: url('../../assets/c-bj.jpg');}
      &3 { background-image: url('../../assets/c-qh.jpg');}
      &4 { background-image: url('../../assets/c-fz.jpg');}
      &5 { background-image: url('../../assets/c-dg.jpg');}
      &6 { background-image: url('../../assets/c-ll.jpg');}
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: rgba(0,0,0,.2);
      }
    }
    .dot {
      margin: 0 6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
      box-shadow: rgba(0,0,0,.7) 1px 1px 1px;
    }
    .t1 {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-size: 23px;
      color: #fff;
      font-weight: bold;
      text-shadow: rgba(0,0,0,.7) 1px 1px 1px;
    }
    .t2 {
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      text-shadow: rgba(0,0,0,.7) 1px 1px 1px;
    }
  }
}