.contact-page {
  padding-top: 170px;
  &-title {
    width: 1500px;
    margin: 0 auto 70px;
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #323232;
  }
  .map-container {
    padding-bottom: 170px;
    display: flex;
    align-items: center;
    width: 1500px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .map-box {
    margin-bottom: 118px;
    width: 648px;
    height: 430px;
    border-radius: 50px;
    border: 1px solid #aaaaaa;
  }
  .txt {
    font-size: 14px;
    color: #0f0f0f;
    line-height: 1.8;
  }
}