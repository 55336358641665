.wisdom-page {
  padding-top: 70px;
  .intro-txt {
    width: 1300px;
    margin: 0 auto;
    font-size: 14px;
    color: #000;
    line-height: 1.8;
  }
  .intro {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto 96px;
    width: 1300px;
    ul {
      flex: 1;
    }
    li {
      margin-bottom: 20px;
      width: 160px;
      height: 52px;
      line-height: 50px;
      text-align: center;
      border: 1px solid #5b5b5b;
      border-radius: 10px;
      font-size: 14px;
      color: #000;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &-sys {
    padding-top: 40px;
    width: 100%;
    background: #fcfcfc;
  }
  .sys-list {
    width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #000;
    .title {
      font-size: 22px;
    }
    .rank {
      margin-bottom: 32px;
      font-size: 22px;
      font-weight: bold;
    }
    .txt {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }
  .auto {
    padding-bottom: 60px;
    img {
      margin-bottom: 32px;
      width: 100%;
      display: block;
      cursor: pointer;
    }
  }
  .modal {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: rgba(0, 0, 0, .8);
    transition: all .3s linear;
    z-index: 9999999;
    &-container {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      .swiper-container {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pic-box {
        padding: 0 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pic {
        display: block;
        max-width: 100%;
        // max-height: 90%;
      }
      .iconfont {
        position: absolute;
        top: 50%;
        z-index: 999;
        color: #fff;
        font-size: 36px;
        cursor: pointer;
        transition: all .3s linear;
        &:hover {
          color: rgba(255, 255, 255, .8);
        }
        &.swiper-button-disabled {
          cursor: not-allowed;
          color: rgba(255, 255, 255, .5);
        }
      }
      .prev {
        left: 24px;
      }
      .next {
        right: 24px;
      }
    }
    .modal-close {
      position: fixed;
      right: -100px;
      top: -100px;
      z-index: 100;
      width: 200px;
      height: 200px;
      line-height: 280px;
      text-indent: 45px;
      background: rgba(255, 255, 255, 0.2) url('../../assets/video-close.png') no-repeat;
      background-position: 25% 75%;
      border-radius: 50%;
    }
    .modal-close:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}