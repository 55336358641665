.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 0 210px;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, .9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);
  &-name {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #333;
    font-size: 26px;
    .dot {
      margin: 0 12px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #333;
    }
  }
  .nav-list {
    display: flex;
    align-items: center;
    li {
      margin-left: 30px;
      &.active a {
        color: #d64648;
      }
      &:hover {
        a {
          color: #d64648;
        }
        .iconfont {
          color: #d64648;
        }
      }
    }
  }
  a {
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: all .3s linear;
    .iconfont {
      margin-right: 10px;
      transition: all .3s linear;
    }
  }
}