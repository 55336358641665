.about-page {
  padding-top: 70px;
  .intro {
    width: 900px;
    margin: 0 auto;
    font-size: 14px;
    color: #373737;
    line-height: 1.8;
  }
  .org {
    position: relative;
    width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &::after {
      content: '';
      position: absolute;
      top: 42px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: #7b7b7b;
    }
    &-title {
      margin-bottom: 50px;
      font-size: 18px;
      color: #444444;
    }
    &-txt {
      font-size: 14px;
      color: #505050;
      line-height: 2;
    }
  }
  .project {
    position: relative;
    width: 1620px;
    .prev,
    .next {
      font-size: 48px;
      color: #9a3636;
      cursor: pointer;
      z-index: 999;
      &.swiper-button-disabled {
        color: #767070;
        cursor: not-allowed;
      }
    }
    .prev {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    .swiper-slider-box {
      margin: 0 auto;
      width: 1500px;
      display: flex;
      flex-wrap: wrap;
      height: 620px;
      justify-content: space-between;
      align-content: space-between;
    }
    .img-box {
      position: relative;
      width: 478px;
      height: 300px;
      background: #e2e2e2;
      cursor: pointer;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0, 0, 0, .3);
        transition: all .2s linear;
        opacity: 0;
      }
      &:hover::before {
        opacity: 1;
      }
    }
    .img-box1 {
      width: 984px;
    }
    .img-box2 {
      width: 100%;
      height: 100%;
    }
    .p-name {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 2;
      text-align: center;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 0 10px rgba(0, 0, 0, .8);
      transform: translateY(-50%);
    }
  }
  .ywly {
    &-sec {
      background: #fafafa;
    }
    &-content {
      padding: 60px 0 50px 105px;
      margin: 0 auto 5px;
      width: 1500px;
      display: flex;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        img {
          align-self: flex-end;
        }
      }
      .right {
        flex: 1;
        margin-left: 372px;
        li {
          margin-bottom: 30px;
          font-size: 14px;
          color: #3e3e3e;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: '';
            margin-right: 14px;
            display: block;
            width: 10px;
            height: 2px;
            background: #848484;
          }
        }
      }
      .title {
        font-size: 22px;
        color: #3e3e3e;
      }
      .rank {
        margin: 6px 0 20px;
        font-size: 14px;
        color: #3e3e3e;
      }
    }
  }
  .modal {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: rgba(0, 0, 0, .8);
    transition: all .3s linear;
    z-index: 9999999;
    &-container {
      margin: 0 auto;
      width: 1440px;
      height: 100%;
      .swiper-container {
        position: relative;
        width: 1440px;
        height: 100%;
      }
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pic-box {
        padding: 0 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pic {
        display: block;
        max-width: 100%;
        max-height: 90%;
      }
      .iconfont {
        position: absolute;
        top: 50%;
        z-index: 999;
        color: #fff;
        font-size: 36px;
        cursor: pointer;
        transition: all .3s linear;
        &:hover {
          color: rgba(255, 255, 255, .8);
        }
        &.swiper-button-disabled {
          cursor: not-allowed;
          color: rgba(255, 255, 255, .5);
        }
      }
      .modal-prev {
        left: 0;
      }
      .modal-next {
        right: 0;
      }
    }
    .modal-close {
      position: fixed;
      right: -100px;
      top: -100px;
      z-index: 100;
      width: 200px;
      height: 200px;
      line-height: 280px;
      text-indent: 45px;
      background: rgba(255, 255, 255, 0.2) url('../../assets/video-close.png') no-repeat;
      background-position: 25% 75%;
      border-radius: 50%;
    }
    .modal-close:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  
  
  
}