.index-page {
  .swiper-container {
    position: relative;
    height: 785px;
    .img-box {
      width: 100%;
      height: 100%;
      p {
        position: absolute;
        left: -360px;
        bottom: 54px;
        width: 360px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        color: #fff;
        font-weight: bold;
        font-size: 22px;
        text-shadow: 0 0 10px rgba(0, 0, 0, .5);
        background: rgba(173, 173, 173, .5);
        opacity: 0;
        transition: all .3s linear;
        &.active {
          left: 0;
          opacity: 1;
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      bottom: 60px;
      width: 100%;
      z-index: 999;
      .swiper-pagination-bullet {
        display: inline-block;
        margin-left: 30px;
        position: relative;
        width: 80px;
        height: 4px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
        cursor: pointer;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9999;
            content: "";
            display: block;
            width: 80px;
            height: 4px;
            border-radius: 2px;
            background: #d64648;
            animation: myAnimate 5s linear;
            // animation-iteration-count: infinite;
          }
        }
      }
    }
  }
  .about {
    height: 270px;
    padding: 60px 0 0 490px;
    background: url("../../assets/aboutbg.png") no-repeat;
    background-size: cover;
    .title {
      margin-bottom: 40px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    .desc {
      margin-bottom: 20px;
      font-size: 14px;
      color: #333;
    }
    .more {
      color: #323232;
      transition: all .3s linear;
      &:hover,
      span {
        color: #c64f4f;
      }
    }
  }
  .ywlw {
    display: flex;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
    }
    .img {
      margin-bottom: 2px;
      width: 248px;
      height: 265px;
    }
    .txt {
      height: 60px;
      font-size: 16px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      background: #7a7a7a;
    }
  }
  .szcs-img {
    height: 500px;
  }
  .zxly-ct {
    margin-bottom: 110px;
    display: flex;
    justify-content: space-between;
    textarea {
      padding: 40px 15px;
      resize: none;
      width: 650px;
      height: 270px;
      border-radius: 15px;
      outline: none;
      background: #f9f9f9;
      border-color: #eaeaea;
      font-size: 14px;
      &::placeholder {
        color: #d4d4d4;
      }
    }
    .right {
      width: 724px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-bottom {
        display: flex;
        align-items: center;
        input {
          padding: 0 18px;
          margin-right: 12px;
          height: 60px;
          width: 100px;
          background: #f9f9f9;
          border: 1px solid #eaeaea;
          border-radius: 20px;
          outline: none;
          font-size: 24px;
        }
      }
      .c-box {
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 60px;
        border-radius: 20px;
        background: #f9f9f9;
        border: 1px solid #eaeaea;
      }
      .btn {
        width: 100px;
        height: 60px;
        border-radius: 20px;
        background: #f9f9f9;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        cursor: pointer;
        transition: all .3s linear;
        &:hover {
          border: 1px solid #d64648;
        }
      }
    }
    .at-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .input1 {
      padding: 0 20px;
      width: 345px;
      height: 60px;
      border-radius: 20px;
      outline: none;
      font-size: 14px;
      background: #f9f9f9;
      border: 1px solid #eaeaea;
      &::placeholder {
        color: #d4d4d4;
      }
    }
    .input2 {
      padding: 0 20px;
      // margin-top: 48px;
      width: 100%;
      height: 60px;
      border-radius: 20px;
      outline: none;
      font-size: 14px;
      background: #f9f9f9;
      border: 1px solid #eaeaea;
      &::placeholder {
        color: #d4d4d4;
      }
    }
  }
}
@keyframes myAnimate {
  from {
    width: 0;
  }
  to {
    width: 80px;
  }
}
