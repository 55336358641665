.sec-title {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-main {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .titleText {
        color: #323232;
        padding-left: 10px;
        font-size: 28px;
    }
    &-more {
        margin-top: 4px;
        color: #323232;
        transition: all 0.3s linear;
        &:hover,
        span {
            color: #c64f4f;
        }
    }
}
